import mock from '../mock'
import { paginateArray } from '../utils'

const data = [
  {
    itemId: 1,
    name: 'Crew get in',
    start_time: '15:00',
    end_time: '',
    status: 3,
    information: ''
  },
  {
    itemId: 2,
    name: 'Load in artist',
    start_time: '16:00',
    end_time: '',
    status: 2,
    information: ''
  },
  {
    itemId: 3,
    name: 'Dinner',
    start_time: '18:00',
    end_time: '19:00',
    status: 2,
    information: ''
  },
  {
    itemId: 4,
    name: 'Doors',
    start_time: '19:00',
    end_time: '',
    status: 1,
    information: ''
  },
  {
    itemId: 5,
    name: 'Showtime',
    start_time: '20:00',
    end_time: '',
    status: 1,
    information: ''
  }
]

mock.onGet('/apps/schedule/initial-data').reply(config => {
  return [200, data]
})

mock.onGet('/apps/schedule/data').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1 } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.filter(
    item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      item.name.toLowerCase().includes(queryLowered) ||
      item.start_time.toLowerCase().includes(queryLowered) ||
      item.end_time.toLowerCase().includes(queryLowered) ||
      item.information.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})
