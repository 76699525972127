import mock from '../mock'
/* eslint-disable */
import { paginateArray, sortCompare, randomDate, getRandomInt } from '../utils'

const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
const nextWeek = new Date(nextDay.getTime() + 7 * 24 * 60 * 60 * 1000)
//const mondayService = require('../../@core/services/monday-service')

import axios from "axios"
//import { useEffect, useState } from "react"
//const initMondayClient = require('monday-sdk-js')
//const { REACT_APP_MONDAY_TOKEN } = process.env

//const tokenByscenen = REACT_APP_MONDAY_TOKEN
//const boardId = 1301069659
import { format, formatISO } from "date-fns"
/*
;(async () => {
  const result = await getAllEvents()
  //console.log(result)
})()*/


const data = {
  products: [
    {
      id: 1,
      name: 'Ary',
      slug: 'ary-1',
      description:
        'Etter å ha debutert som produsent med «Oh My God» sommeren 2020 har ARY levert oppfølgere som (alt pop)perler på ei snor. «Angels», «Moth To A Flame» og sist «Hurt You», et samarbeid mellom Ariadne og ingen ringere enn Emilie Nicolas. Da ARY trakk seg tilbake fra konserter og rampelys i 2018 var det for å eksperimentere, og for å utvikle et lydbilde som var helt hennes eget. Etter flere år med hodet dypt begravet i synth, sorg og poesi er Ariadne igjen klar for å vise frem musikken i det formatet hun kjenner aller best – live. “Det er så godt å ha ARY tilbake. Men også litt vondt.” – Eskil Olaf Vestre, NRK',
      genre: 8,
      tickets_sold: 70,
      venue_cap: 100,
      price: 10.99,
      date: '2021-05-20',
      image: require('@src/assets/images/pages/events/ary.jpg').default,
      hasFreeShipping: true,
      rating: 2,
      rider: true,
      venue:'storesal'
    }
  ],
  userWishlist: [
    { id: 1, productId: 1 }
  ],
  userCart: [
    { id: 1, productId: 1, qty: 1 }
  ]
}


// ------------------------------------------------
// GET: Return products
// ------------------------------------------------
mock.onGet('/apps/events/events').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', sortBy = 'latest-event', perPage = 9, page = 1 } = config.params

  const queryLowered = q.toLowerCase()
/*
  async function fetchByscenen() {
    try {
      const mainEventBoard = 815992074
      const mainEventGroup = "new_group79704"
      const queryShows = await mondayService.listByscenenBoardItems(tokenByscenen, mainEventBoard, mainEventGroup, 10)
      const allShowsData = {
        allShowsId: queryShows.id,
        allShowsName: queryShows.name
      }
      return allShowsData
      //allShowsData.crew.push(soundTech)
    } catch (error) {
      console.log(error)
    }
  }*/


  //fetchByscenen()
  
  const filteredData = data.products.filter(product => product.name.toLowerCase().includes(queryLowered))

  let sortDesc = false
  const sortByKey = (() => {
    if (sortBy === 'tickets-desc') {
      sortDesc = true
      return 'tickets_sold'
    }
    if (sortBy === 'tickets-asc') {
      return 'tickets_sold'
    }
    if (sortBy === 'latest-event') {
      sortDesc = true
      return new Date('date')
    }
    if (sortBy === 'oldest-event') {
      return new Date('date')
    }
    sortDesc = true
    return 'id'
  })()

  const sortedData = filteredData.sort(sortCompare(sortByKey))
  if (sortDesc) sortedData.reverse()

  const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

  paginatedData.forEach(product => {
    /* eslint-disable no-param-reassign */
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
    product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1
    /* eslint-enable */
  })

  return [
    200,
    {
      products: paginatedData,
      total: filteredData.length,
      userWishlist: data.userWishlist,
      userCart: data.userCart//,
      //monday: queryShows
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Product
// ------------------------------------------------
mock.onGet(/\/apps\/events\/events\/\d+/).reply(config => {
  // Get product id from URL
  let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  productId = Number(productId)

  const productIndex = data.products.findIndex(p => p.id === productId)
  const product = data.products[productIndex]

  if (product) {
    // Add data of wishlist and cart
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
    product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1

    // * Add Dummy data for details page
    product.colorOptions = ['primary', 'success', 'warning', 'danger', 'info']

    return [200, { product }]
  }
  return [404]
})
