import mock from '../mock'

const data = {
  lmfkDay: {
    extraNote: 'Test1',
    extraNote2: 'Test2',
    nextByscenen: '2021-06-21T08:15:00Z',
    daysheetLMFK: [
      {
        name: 'Lerret og Projektor klar',
        startTime: '09:45',
        endTime: '14:00',
        groups: '',
        description: 'Rigges av Thomas'
      }
    ]
  }
}

mock.onGet('/monday/lmfk/daysheet').reply(() => [200, data.lmfkDay])
//mock.onGet('/monday/byscenen/events/details').reply(() => [200, data.details])
