// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}
// ** Returns Initial Menu Collapsed State
const initialTicketHidden = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  overrideMode: themeConfig.layout.overrideMode,
  hideCrewModule: themeConfig.layout.hideCrewModule,
  hideCrewPhone: themeConfig.layout.hideCrewPhone,
  hideCrewEmail: themeConfig.layout.hideCrewEmail,
  hideTicketModule: themeConfig.layout.hideTicketModule,
  hideStatusModule: themeConfig.layout.hideStatusModule,
  hideNextEvent: themeConfig.layout.hideNextEvent,
  hideHeaderModule: themeConfig.layout.hideHeaderModule
}

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_CONTENT_WIDTH':
      return { ...state, contentWidth: action.value }
    case 'HANDLE_MENU_COLLAPSED':
      window.localStorage.setItem('menuCollapsed', action.value)
      return { ...state, menuCollapsed: action.value }
    case 'HANDLE_MENU_HIDDEN':
      return { ...state, menuHidden: action.value }
    case 'HANDLE_RTL':
      return { ...state, isRTL: action.value }
    case 'HANDLE_OVERRIDEMODE':
      return { ...state, overrideMode: action.value }
    case 'HANDLE_CREWMODULE_HIDDEN':
      return { ...state, hideCrewModule: action.value }
    case 'HANDLE_CREWMPHONE_HIDDEN':
      return { ...state, hideCrewPhone: action.value }
    case 'HANDLE_CREWMEMAIL_HIDDEN':
      return { ...state, hideCrewEmail: action.value }
    case 'HANDLE_TICKETMODULE_HIDDEN':
      return { ...state, hideTicketModule: action.value }
    case 'HANDLE_STATUSMODULE_HIDDEN':
      return { ...state, hideStatusModule: action.value }
    case 'HANDLE_NEXTEVENTMODULE_HIDDEN':
      return { ...state, hideNextEvent: action.value }
    case 'HANDLE_HEADERMODULE_HIDDEN':
      return { ...state, hideHeaderModule: action.value }
    default:
      return state
  }
}

export default layoutReducer
